import React from 'react';
import Layout from '../layout/layout';
import { Link } from 'gatsby';

import Illustration404 from '../images/icons/404/illustration-404.svg';
import IllustrationBallon from '../images/icons/404/illustration-balloon.svg';
import IllustrationTrees from '../images/icons/404/illustration-trees.svg';


const NotFoundPage = () => (
  <Layout invertHeader={true}>
  	<section className="page-not-found">
	  	<div className="container text-center">
		    <h1>Page not found</h1>
		    <p>Sorry, we couldn't find the page you are looking for.<br />Please go back to the homepage.</p>
				<Link to="/" className="btn btn-sm btn-blue">Return to the homepage</Link>
	    	<img src=/{Illustration404} className="illustration-404" alt="404" />
	    	<img src=/{IllustrationBallon} className="illustration-ballon" alt="balloon" />
	    	<img src=/{IllustrationTrees} className="illustration-trees" alt="trees" />
	    </div>
    </section>
  </Layout>
)

export default NotFoundPage;
